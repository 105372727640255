<template>
  <div>
    <DataTable
      v-if="!isLoading"
      :data="flattenData"
      :headers="columnHeaders"
      enable-export
      enable-search
      :filter-fields="[ 'rd_col_1', 'cost_category', 'qda' ]"
      :footers="columnFooters"
      :is-loading="isLoading"
      export-file-name="Cost Breakdown Table"
    />
  </div>
</template>

<script setup>
import { computed, ref } from "vue";
import { get } from "@shared/http.js";
import { formatCurrency, formatPercentage } from "@shared/helpers";
import DataTable from "@generic/data_table.vue";

const rdProjects = ref([]);
const costBreakdownData = ref([]);
const errorMessage = ref("");
const isLoading = ref(true);

const props = defineProps({
  claimPeriodSlug: {
    type: String,
    required: true
  }
});

Promise.all([
  getRdProjects(),
  getCostBreakdownData()
]).then(() => {
  isLoading.value = false;
});

async function getRdProjects() {
  const res = await get(`/api/rd_projects?claim_period_id=${props.claimPeriodSlug}`);

  if (res.error) {
    errorMessage.value = "There was an error fetching the rd projects.";
    return;
  }

  rdProjects.value = res;
}

async function getCostBreakdownData() {
  const res = await get(`/api/v1/claim_period/${props.claimPeriodSlug}/subcosts`);

  if (res.error) {
    errorMessage.value = "There was an error fetching the costs breakdown data.";
    return;
  }

  costBreakdownData.value = res;
}

const baseFooterColumns = computed(() => [
  { footer: "Totals:", colspan: 4, style: "text-align:right; background-color: #ededed" },
  { footer: formatCurrency(totalExpenditure.value) },
  { footer: formatPercentage(totalPcAllocated.value) },
  { footer: formatCurrency(totalTqe.value) }
]);

const columnFooters = computed(() => [
  [
    ...baseFooterColumns.value,
    ...rdProjects.value.flatMap(rdProject => [
      { footer: formatPercentage(totalPcAllocatedPerProject.value[rdProject.slug] || 0), field: `rd_project_${rdProject.slug}_allocation` },
      { footer: formatCurrency(totalTqeAllocatedPerProject.value[rdProject.slug] || 0), field: `rd_project_${rdProject.slug}_tqe_allocated` }])
  ]
]);

const baseHeaderColumns = ref([
  { header: "Name/Supplier", field: "rd_col_1", sortable: true, filterField: "rd_col_1", frozen: true },
  { header: "Job Title/Description", field: "rd_col_2", sortable: true },
  { header: "Cost Category", field: "cost_category", sortable: true, filterField: "cost_category" },
  { header: "Qualifying Activity", field: "qda", sortable: true, filterField: "qda" },
  { header: "Total Expenditure", field: "total_expenditure", sortable: true, formatter: formatCurrency, formatterArguments: [false, true] },
  { header: "R&D %", field: "pc_rd", sortable: true, formatter: formatPercentage, formatterArguments: [true] },
  { header: "Total Qualifying Expenditure", field: "tqe", sortable: true, formatter: formatCurrency, formatterArguments: [false, true] }
]);

const columnHeaders = computed(() => {
  if (rdProjects.value.length === 0) {
    return [baseHeaderColumns.value];
  }
  return [
    [
      ...baseHeaderColumns.value,
      ...rdProjects.value.map(rdProject => ({ header: rdProject.title, colspan: 2, rowspan: 1 }))
    ],
    [
      ...rdProjects.value.flatMap(rdProject => [
        {
          header: "% Allocation",
          field: `rd_project_${rdProject.slug}_allocation`,
          sortable: true,
          formatter: formatPercentage,
          formatterArguments: [true],
          exportHeader: `% Allocation for ${rdProject.title}`
        },
        {
          header: "TQE Allocated",
          field: `rd_project_${rdProject.slug}_tqe_allocated`,
          sortable: true,
          formatter: formatCurrency,
          formatterArguments: [false, true],
          exportHeader: `TQE Allocated for ${rdProject.title}`
        }
      ])
    ]
  ];
});

const flattenData = computed(() => costBreakdownData.value.map(data => ({
  ...data,
  ...Object.fromEntries(
    rdProjects.value.map(({ slug }) => [
      [`rd_project_${slug}_allocation`, data.project_breakdowns?.[slug]?.percent_allocation || 0],
      [`rd_project_${slug}_tqe_allocated`, data.project_breakdowns?.[slug]?.tqe_allocated || 0]
    ]).flat()
  )
})));

const totalExpenditure = computed(() => costBreakdownData.value.reduce((total, data) => total + Number((data.total_expenditure) || 0), 0));

const totalTqe = computed(() => costBreakdownData.value.reduce((total, data) => total + Number((data.tqe) || 0), 0));

const totalPcAllocated = computed(() => (totalTqe.value / totalExpenditure.value) * 100);

const totalTqeAllocatedPerProject = computed(() => {
  const projectTotals = {};

  costBreakdownData.value.forEach(data => {
    Object.entries(data.project_breakdowns || {}).forEach(([slug, breakdown]) => {
      if (!projectTotals[slug]) {
        projectTotals[slug] = 0;
      }
      projectTotals[slug] += breakdown.tqe_allocated || 0;
    });
  });

  return projectTotals;
});

const totalPcAllocatedPerProject = computed(() => {
  const projectTotals = {};

  rdProjects.value.forEach(rdProject => {
    projectTotals[rdProject.slug] = 0;
  });

  rdProjects.value.forEach(rdProject => {
    const allocated = totalTqeAllocatedPerProject.value[rdProject.slug];

    if (allocated > 0) {
      projectTotals[rdProject.slug] = (allocated / totalTqe.value) * 100;
    } else {
      projectTotals[rdProject.slug] = 0;
    }
  });

  return projectTotals;
});

</script>
