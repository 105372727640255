<template>
  <div>
    <DataTable
      :data="flattenData"
      :columns="tableColumns"
      enable-search
      :paginator-rows="20"
      :filter-fields="['name']"
      :is-loading="false"
    />
  </div>
</template>

<script setup>
import { computed } from "vue";
import DataTable from "@generic/data_table.vue";

const props = defineProps({
  claims: {
    type: Array,
    required: true
  }
});

const flattenData = computed(() => props.claims.map(data => ({
  ...data,
  ...Object.fromEntries(
    ["ready_for_client_approval", "can_be_pre_prepped"].map(check => [
      [`${check}_fraction`, data[check].success_count / data[check].total_count],
      [`${check}_label`, `${data[check].success_count} / ${data[check].total_count}`],
    ]).flat()
  )
})));

const claimManagers = computed(() => [...new Set(props.claims.map(claim => claim.claim_manager))]);

const tableColumns = [
  {
    header: "Claim Period",
    field: "name",
    sortable: true,
    link: "claim_link"
  },
  {
    header: "Claim Manager",
    field: "claim_manager",
    sortable: true,
    filter: true,
    filterOptions: claimManagers.value
  },
  {
    header: "Can be Pre-Prepped",
    field: "can_be_pre_prepped_label",
    sortable: true,
    sortField: "can_be_pre_prepped_fraction",
    link: "can_be_pre_prepped_link"
  },
  {
    header: "Claim Readiness",
    field: "ready_for_client_approval_label",
    sortable: true,
    sortField: "ready_for_client_approval_fraction",
    link: "ready_for_client_approval_link"
  }
];

</script>
