<template>
  <div
    v-for="section in sections"
    :key="section"
    class="tw-gap-x-6 sm:tw-gap-x-6"
  >
    <div class="tw-flex tw-items-start tw-justify-between tw-gap-4">
      <div class="tw-flex tw-flex-col tw-gap-2">
        <div v-if="!newProject" :class="['tw-flex-1', section.type == 'Status' ? 'tw-text-2xl tw-font-bold' : 'tw-text-xl tw-font-semibold tw-text-blue-gray-900']">
          {{ section.type == 'Status' ? truncate(sectionTypeName(section), 70) : 'Editing ' + truncate(sectionTypeName(section), 70) }}
        </div>
        <state-wrapper component="SectionKnowledgeCentre">
          <span v-show="section.type === 'Project'">
            <KnowledgeCentreTriggerIcon v-if="newProject" question="what_is_a_project" :section="section.type" />
            <KnowledgeCentreTriggerIcon v-else faq="what-is-a-project" />
          </span>
        </state-wrapper>
      </div>
      <div class="tw-flex-none">
        <slot name="actions" />
      </div>
    </div>
    <div v-if="newProject" class="tw-mt-2">
      <banner-component
        type="cta"
        :dismiss="true"
      >
        <template #content>
          <div>
            <b>Avoid using AI/LLMs to address project questions. </b>
            Using AI/LLMs weakens the quality of information and reduces the efficiency of the process. Additionally, HMRC may use AI detection software to flag submissions written with AI, increasing the risk of enquiry.
          </div>
        </template>
      </banner-component>
    </div>
    <div v-if="getStateMessage && getUserType != 'admin'" class="tw-mt-2">
      <banner-component
        :text="getStateMessage"
        type="disabled"
        :dismiss="true"
      />
    </div>
    <div class="tw-flex tw-justify-between tw-w-full tw-gap-x-6 tw-mt-4">
      <div
        v-if="section.fields.length > 0"
        class="tw-flex-1 tw-min-w-0 tw-flex tw-flex-col tw-gap-4"
      >
        <FieldGroups :options="options" :section="section" />
      </div>
      <div class="tw-flex-none tw-w-fit lg:tw-w-80 2xl:tw-w-1/3">
        <SidePanel :section="section" :options="options" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import FieldGroups from "./field_groups.vue";
import KnowledgeCentreTriggerIcon from "../../generic/knowledge_centre_trigger_icon.vue";
import SidePanel from "./side_panel.vue";
import { patch } from "../../../shared/http.js";

import { GET_STATE_MESSAGE,
  GET_EDIT_STATE,
  GET_USER_TYPE } from "../../../vuex-store/modules/states.js";
import { GET_NEW_PROJECT } from "../../../vuex-store/modules/sections.js";

import useEventsBus from "../../../shared/eventBus";
import { truncate } from "../../../shared/helpers.js";

export default {
  components: {
    FieldGroups,
    KnowledgeCentreTriggerIcon,
    SidePanel
  },
  props: {
    sections: {
      type: Array,
      default: () => []
    },
    options: {
      type: Object,
      default: () => ({})
    }
  },
  setup() {
    const { emit } = useEventsBus();

    function triggerNewProjectTour() {
      emit("openAllFieldGroups");
      triggerTour("BlKJKf2wNA");
      patch("/api/users/helphero_tour_action/started_tour", { tour: "New Project Methodology" });
    }

    return {
      triggerNewProjectTour
    };
  },
  computed: {
    ...mapGetters({
      getStateMessage: `states/${GET_STATE_MESSAGE}`,
      getEditState: `states/${GET_EDIT_STATE}`,
      getUserType: `states/${GET_USER_TYPE}`,
      newProject: `sections/${GET_NEW_PROJECT}`
    }),
    isLoading() {
      return (this.sections || []).length == 0;
    }
  },
  methods: {
    knowledgeTrigger(section) {
      if (section === "Project") {
        return "what-is-a-project";
      }
    },
    sectionTypeName(section) {
      if (section.type == "Grant") {
        return "Source of External Funding";
      } if (section.type == "Status") {
        return "Business Questionnaire";
      }
      const titleField = section.fields[0];
      if (titleField.label === "title") {
        return `${section.type} Details (${
          truncate(titleField.value, 30) || "Untitled"
        })`;
      }
    },
    truncate
  }
};
</script>
